<template>
    <div>
      <b-row>
        <b-col lg="8">
          <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-tree-planning') }}</p>
          <p><strong class="mr-2">{{ $t('step1') }}</strong>{{ $t('feature-tree-planning-content') }}</p>
          <div class="text-center mb-4">
            <img src="/assets/image/blog/manual-12.png" height="312px">
            <p><i>Giao diện chính của website</i></p>
          </div>
          <p><strong class="mr-2">{{ $t('step2') }}</strong>{{ $t('feature-tree-planning-content-2') }}</p>
          <div class="text-center mb-4">
            <img src="/assets/image/blog/manual-2.png" height="312px">
            <p><i>{{ $t('feature-tree-planning-content-3') }}</i></p>
          </div>
          <p>{{ $t('feature-tree-planning-content-4') }}</p>
          <div class="text-center mb-4">
            <img src="/assets/image/blog/manual-3.png" height="312px">
            <p><i>{{ $t('feature-tree-planning-content-5') }}</i></p>
          </div>
          <p>{{ $t('feature-tree-planning-content-6') }}</p>
          <div class="text-center mb-4">
            <img src="/assets/image/blog/manual-3.png" height="312px">
            <p><i>{{ $t('feature-tree-planning-content-7') }}</i></p>
          </div>
          <p>{{ $t('feature-tree-planning-content-8') }}</p>
          <div class="text-center mb-4">
            <img src="/assets/image/blog/manual-4.png" height="312px">
            <p><i>{{ $t('feature-tree-planning-content-9') }}</i></p>
          </div>
          <p>{{ $t('feature-tree-planning-content-10')  }}</p>
          <div class="text-center mb-4">
            <img src="/assets/image/blog/manual-5.png" height="312px">
            <p><i>{{ $t('feature-tree-planning-content-11') }}</i></p>
          </div>
        </b-col>
        <b-col lg="4">
          <RightMenu />
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import RightMenu from "@/layout/RightMenu";
  export default {
    components: {
      RightMenu,
    },
  };
  </script>
  
  <style scoped>
  .separator {
    background: #e6e8ec;
    height: 1px;
  }
  .menu-content {
    color: var(--website-neutral-60, #18191a);
    /* Website & tablet/Menu */
    font-size: 14px;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    text-transform: uppercase;
  }
  .menu-title {
    color: var(--brand-color, #c71313);
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .right-menu {
    border-radius: 24px;
    border: 1px solid var(--website-gray, #e6e8ec);
    background: var(--website-white, #fff);
    padding: 32px;
  }
  .font-64 {
    font-size: 64px;
  }
  .font-24 {
    font-size: 24px;
  }
  .title-block {
    font-size: 12px;
    font-weight: 300;
    color: #636466;
    width: 75%;
  }
  .title-secondary {
    font-size: 16px;
    font-weight: 300;
    color: #636466;
    width: 75%;
    margin-bottom: 40px !important;
  }
  .content-slogan {
    color: var(--website-brand-color, #c71313);
    /* Website & tablet/Header 1 */
    font-size: 48px;
    font-weight: 400;
    line-height: 60px; /* 125% */
    margin-bottom: 40px !important;
  }
  .content-card {
    border: 1px solid #e6e8ec;
    border-radius: 24px;
    overflow: hidden;
    transition: 500ms;
    cursor: pointer;
  }
  .content-card p {
    height: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .content-card:hover {
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  }
  </style>
  