<template>
  <div class="right-custom-menu">
    <p class="menu-title mb-4">{{ $t("overview") }}</p>
    <p
      class="menu-content mb-4"
      :class="path.includes('overview') ? 'active' : ''"
      @click="handleClick('overview')"
    >
      {{ $t("overview") }}
    </p>
    <p
      class="menu-content mb-4"
      :class="path.includes('blog2') ? 'active' : ''"
      @click="handleClick('blog2')"
    >
      {{ $t("mean-content-11") }}
    </p>
    <!-- <p class="menu-content mb-4" @click="handleClick('feature')">Lợi ích khi sử dụng ứng dụng</p> -->

    <p
      class="menu-content mb-4"
      :class="path.includes('app') ? 'active' : ''"
      @click="handleClick('app')"
    >
      {{ $t("app") }}
    </p>
    <div class="separator mb-4"></div>
    <p class="menu-title mb-4">{{ $t("topic-tab") }}</p>
    <p
      class="menu-content mb-4"
      :class="path.includes('featuretreedetail') ? 'active' : ''"
      @click="handleClick('featuretreedetail')"
    >
      {{ $t("feature-tree-info") }}
    </p>
    <p
      class="menu-content mb-4"
      :class="path.includes('featureplanning') ? 'active' : ''"
      @click="handleClick('featureplanning')"
    >
      {{ $t("feature-info2") }}
    </p>
    <p
      class="menu-content mb-4"
      :class="path.includes('blog') ? 'active' : ''"
      @click="handleClick('blog')"
    >
      {{ $t("feature-info") }}
    </p>
    <div class="separator mb-4"></div>
    <p class="menu-title mb-4">{{ $t("guide-tab") }}</p>
    <p
      class="menu-content mb-4"
      :class="path.includes('manual') ? 'active' : ''"
      @click="handleClick('manual')"
    >
      {{ $t("manual-guide") }}
    </p>
    <p
      class="menu-content mb-4"
      :class="path.includes('featuretreeplanning') ? 'active' : ''"
      @click="handleClick('featuretreeplanning')"
    >
      {{ $t("feature-tree-planning") }}
    </p>
  </div>
</template>

<script>
export default {
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  data() {
    return {
      path: this.$root._route.fullPath,
    };
  },
  methods: {
    handleClick(route) {
      this.$router.push(`/guideline/${route}`);
    },
    fetchData() {
      this.path = this.$root._route.fullPath;
    },
  },
};
</script>

<style scoped>
.separator {
  background: #063;
  height: 1px;
}
.menu-content {
  color: var(--website-neutral-60, #18191a);
  /* Website & tablet/Menu */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  cursor: pointer;
}
.menu-content:hover {
  color: var(--website-neutral-60, #063);
  /* Website & tablet/Menu */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  cursor: pointer;
}
.active {
  color: var(--website-neutral-60, #063);
  font-weight: 600 !important;
}
.menu-title {
  color: var(--hover, #063);
  /* Maven Pro/24px - Semi Bold */
  font-family: "Maven Pro", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  text-transform: uppercase;
}
.right-custom-menu {
  border-left: 2px solid var(--website-gray, #063);
  padding: 32px;
}
</style>
